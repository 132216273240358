<template>
    <v-overlay
        :value="overlay"
        z-index="9999"
    >
        <v-progress-circular
            indeterminate
            size="32"
        />
    </v-overlay>
</template>
<script>
export default {
    computed: {
        overlay: {
            get() {
                return this.$store.getters['loading/getStatus']
            },
            set(value) {
                this.$store.dispatch('loading/end')
                /* value && setTimeout(() => {

                }, 2000) */
            }
        }
    }
}
</script>
